import ParticleRing from "./component/ParticalRing";

function App() {
  return (
    <div className="App">
        <ParticleRing/>
    </div>
  );
}

export default App;
